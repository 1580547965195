<template>
  <BCard
    class="m-0"
    body-class="min-h-[10vh]"
  >
    <div class="text-black font-medium text-2xl">
      Skill
    </div>
    <BTabs
      class="mt-2"
      content-class="mt-1"
      pills
      active-nav-item-class="rounded-pill"
    >
      <BTab
        title="Skill Role"
        active
        lazy
        title-item-class="bg-[#F8F8F8] rounded-[25px]"
      >
        <ListSkills />
      </BTab>
      <BTab
        title="Keterampilan lain"
        lazy
        title-item-class="bg-[#F8F8F8] rounded-[25px]"
      >
        <ListMoreSkills />
      </BTab>
    </BTabs>
  </BCard>
</template>

<script>
import ListSkills from './ListSkills.vue'
import ListMoreSkills from './ListMoreSkills.vue'

export default {
  components: { ListSkills, ListMoreSkills },
}
</script>
