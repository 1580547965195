<template>
  <BModal
    id="modal-add-more-skill"
    ref="modal-add-more-skill"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    hide-footer
    hide-header
    centered
    body-class="p-2"
    @hide="resetModal()"
  >
    <div class="text-black text-xl font-medium mb-2">
      {{ source === 'edit' ? 'Ubah Keterampilan' : 'Tambah Keterampilan' }}
    </div>
    <ValidationObserver
      ref="formRules"
      #default="{ invalid }"
    >
      <BForm
        class="flex flex-col justify-between min-h-[30vh]"
        @submit.prevent="onFinish"
      >
        <BFormGroup label="Nama Keterampilan">
          <ValidationProvider rules="required">
            <BFormInput
              v-model="name"
              placeholder="Silahkan isi keterampilanmu"
            />
          </ValidationProvider>
        </BFormGroup>
        <div class="flex justify-center">
          <BButton
            size="md"
            variant="outline-primary"
            class="mr-1"
            style="width: 140px"
            @click="$bvModal.hide('modal-add-more-skill')"
          >
            Batal
          </BButton>
          <BButton
            :disabled="invalid || loading || loadingUpdate"
            size="md"
            :variant="name ? 'primary' : ''"
            style="width: 140px"
            type="submit"
            block
          >
            <BSpinner
              v-if="loading || loadingUpdate"
              small
            />
            Submit
          </BButton>
        </div>
      </BForm>
    </ValidationObserver>
  </BModal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'

export default {
  components: { ValidationObserver, ValidationProvider },
  props: {
    getListData: {
      type: Function,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    resetModal: {
      type: Function,
      required: true,
    },
    source: {
      type: String,
      required: true,
    },
    idSkill: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
      loading: false,
      loadingUpdate: false,
      alertError,
      alertSuccess,
    }
  },
  methods: {
    async onFinish() {
      if (this.source === 'edit') {
        this.onUpdate()
      } else {
        this.$refs.formRules.validate().then(async success => {
          if (success) {
            this.loading = true
            const payload = new FormData()
            payload.append('name', this.name)
            payload.append('type', 1)
            const url = '/v1/skills/store'

            await komtimAxiosIns.post(url, payload)
              .then(res => {
                const { meta } = res.data
                this.$bvModal.hide('modal-add-more-skill')
                this.resetModal()
                this.loading = false

                const text = 'Berhasil menambahkan data'
                this.alertSuccess(text)
                this.getListData()
              })
              .catch(err => {
                this.loading = false
                this.alertError(err)
              })
          }
        })
      }
    },
    async onUpdate() {
      this.loadingUpdate = true
      const payload = new FormData()
      payload.append('name', this.name)
      payload.append('type', 1)
      const url = `/v1/skills/${this.idSkill}/update`

      await komtimAxiosIns.put(url, payload)
        .then(res => {
          const { meta } = res.data
          this.loadingUpdate = false
          this.$bvModal.hide('modal-add-more-skill')
          this.getListData()
          this.resetModal()

          const text = 'Berhasil mengubah data'
          this.alertSuccess(text)
        })
        .catch(err => {
          this.loadingUpdate = false
          this.alertError(err)
        })
    },
  },
}
</script>
