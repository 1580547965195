<template>
  <div>
    <div class="text-black">
      <div class="flex justify-content-between align-items-center my-2">
        <div class="d-flex rounded-lg align-items-center border search-bar">
          <span
            class="k-search-normal-1 h-100 font-bold text-10 pt-[7px] align-middle mx-[5px]"
          />
          <BFormInput
            v-model="keyword"
            placeholder="Cari Nama"
            class="border-0"
            @input="handleSearch()"
          />
        </div>
        <BButton
          tag="router-link"
          to="/skill/add"
          variant="primary"
          class="ml-auto px-3"
        >
          <strong>Tambah</strong>
        </BButton>
      </div>
    </div>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="scroll"
        style="height: calc(100vh - 275px); overflow-y: scroll;"
        class="mt-[5px]"
        @scroll="handleScroll(getNextData, loading, lastData)"
      >
        <BTable
          :items="items"
          :fields="fields"
          class="mt-2"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
        >
          <template #cell(id)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(name)="data">
            <div class="flex items-center">
              <img
                v-if="data.item.icon === ''"
                src="https://storage.googleapis.com/komerce/assets/avatars/image-null.png"
                alt="Komerce"
                class="w-[40px] rounded-sm"
              >
              <img
                v-else
                :src="data.item.icon"
                alt="Komerce"
                class="w-[40px] rounded-sm"
              >
              <div class="ml-1">
                {{ data.item.name }}
              </div>
            </div>
          </template>
          <template #cell(action)="data">
            <div class="flex justify-center items-center">
              <BButton
                class="btn-icon mr-50"
                size="sm"
                variant="flat-warning"
                :to="{
                  name: $route.meta.routeEdit,
                  params: { id: data.item.id }
                }"
                tag="router-link"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/edit.svg"
                  alt="Komerce"
                >
              </BButton>
              <BFormCheckbox
                v-model="data.item.active"
                switch
                @change="onUpdate(data.item)"
              />
            </div>
          </template>
        </BTable>
      </div>
    </BOverlay>
  </div>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import { columnConfig, handleScroll } from './config'

export default {
  data() {
    return {
      loading: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      keyword: '',
      fields: columnConfig,
      handleScroll,
      alertError,
      alertSuccess,
    }
  },
  mounted() {
    this.getListData()
  },
  methods: {
    async getListData() {
      this.loading = true
      this.offset = 0
      const params = `offset=${this.offset}&limit=${this.limit}&type=2&keyword=${this.keyword}`
      const url = `v1/skills?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.items = data
          this.offset = data.length
          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async onUpdate(data) {
      const { id, active } = data
      this.loading = true
      const url = `/v1/skills/${id}/update-activation`
      const payload = {
        active,
      }
      await komtimAxiosIns.put(url, payload)
        .then(res => {
          const { meta } = res.data
          this.getListData()
          this.loading = false

          const text = 'Berhasil mengubah data'
          this.alertSuccess(text)
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      if (!this.lastData) {
        this.loading = true
        const params = `offset=${this.offset}&limit=${this.limit}&type=2&keyword=${this.keyword}`
        const url = `v1/skills?${params}`
        await komtimAxiosIns.get(url)
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
      }
    },
    handleSearch: _.debounce(function search() {
      this.getListData()
    }, 1000),
  },

}
</script>

<style scoped>
.search-bar {
  width: 400px;
  height: 40px;
}
.text-10 {
  font-size: 20px;
}
</style>
