// eslint-disable-next-line import/prefer-default-export
export const columnConfig = [
  {
    key: 'id',
    label: 'ID',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    // class: 'p-1',
  },
  {
    key: 'name',
    label: 'Skill',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    // class: 'p-1',
  },
  {
    key: 'action',
    label: 'Aksi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1 text-center',
  },
]

export const handleScroll = (getNextData, loading, lastData) => {
  const table = document.getElementById('scroll')
  if (
    table.scrollTop >= table.scrollHeight - table.offsetHeight - 2
        && !loading
        && !lastData
  ) {
    getNextData()
  }
}
