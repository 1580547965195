<template>
  <div>
    <div class="text-black">
      <div class="my-2 flex justify-content-between align-items-center">
        <div class="d-flex rounded-lg align-items-center border search-bar">
          <span
            class="k-search-normal-1 h-100 font-bold text-10 pt-[7px] align-middle mx-[5px]"
          />
          <BFormInput
            v-model="keyword"
            placeholder="Cari Nama"
            class="border-0"
            @input="handleSearch()"
          />
        </div>
        <BButton
          variant="primary"
          class="ml-auto px-3"
          @click="showModal('add')"
        >
          <strong>Tambah</strong>
        </BButton>
      </div>
    </div>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="scroll"
        style="height: calc(100vh - 275px); overflow-y: scroll;"
        class="mt-[5px]"
        @scroll="handleScroll(getNextData, loading, lastData)"
      >
        <BTable
          :items="items"
          :fields="fields"
          class="mt-1"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
        >
          <template #cell(id)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(action)="data">
            <div class="flex justify-center items-center">
              <BButton
                class="btn-icon mr-50"
                size="sm"
                variant="flat-warning"
                @click="getSkillById({ id: data.item.id, source: 'edit' })"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/edit.svg"
                  alt="Komerce"
                >
              </BButton>
              <BFormCheckbox
                v-model="data.item.active"
                switch
                @change="onUpdate(data.item)"
              />
            </div>
          </template>
        </BTable>
      </div>
    </BOverlay>
    <ModalAddMoreSkill
      :get-list-data="getListData"
      :name="name"
      :reset-modal="resetModal"
      :source="source"
      :id-skill="idSkill"
    />
  </div>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import { columnConfig, handleScroll } from './config'
import ModalAddMoreSkill from './ModalAddMoreSkill.vue'

export default {
  components: { ModalAddMoreSkill },
  data() {
    return {
      loading: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      keyword: '',
      fields: columnConfig,
      name: '',
      initialName: '',
      source: '',
      idSkill: 0,
      handleScroll,
      alertError,
      alertSuccess,
    }
  },
  mounted() {
    this.getListData()
  },
  created() {
    this.initialName = this.name
  },
  methods: {
    async getListData() {
      this.loading = true
      this.offset = 0
      const params = `offset=${this.offset}&limit=${this.limit}&type=1&keyword=${this.keyword}`
      const url = `v1/skills?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.items = data
          this.offset = data.length
          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async onUpdate(data) {
      const { id, active } = data
      this.loading = true
      const url = `/v1/skills/${id}/update-activation`
      const payload = {
        active,
      }
      await komtimAxiosIns.put(url, payload)
        .then(res => {
          const { meta } = res.data
          this.getListData()
          this.loading = false

          const text = 'Berhasil mengubah data'
          this.alertSuccess(text)
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      if (!this.lastData) {
        this.loading = true
        const params = `offset=${this.offset}&limit=${this.limit}&type=1&keyword=${this.keyword}`
        const url = `v1/skills?${params}`
        await komtimAxiosIns.get(url)
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
      }
    },
    async getSkillById(value) {
      const { id, source } = value
      this.source = source
      const url = `/v1/skills/${id}`
      await komtimAxiosIns.get(url)
        .then(res => {
          this.$bvModal.show('modal-add-more-skill')
          const { data } = res.data
          this.name = data.name
          this.idSkill = data.id
        })
    },
    handleSearch: _.debounce(function search() {
      this.getListData()
    }, 1000),
    resetModal() {
      this.name = this.initialName
    },
    showModal(value) {
      this.source = value
      this.$bvModal.show('modal-add-more-skill')
    },
  },
}
</script>

<style scoped>
.search-bar {
  width: 400px;
  height: 40px;
}
.text-10 {
  font-size: 20px;
}
</style>
